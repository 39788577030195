import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { path } from "../../routes/path";
import { cn } from "../../utils/common";

const DomainLogo = () => {
  const { t } = useTranslation();
  return (
    <div className="flex sm:items-center flex-col sm:flex-row">
      <div
        dangerouslySetInnerHTML={{
          __html: t("formSetup.domainLogo.content"),
        }}
      />
      <Link
        to={path.setting}
        target="_blank"
        className={cn(
          "text-secondary hover:text-secondary hover:opacity-70 transition-all"
        )}
      >
        {t("formSetup.domainLogo.setting")}
      </Link>
    </div>
  );
};

export default DomainLogo;
