import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { Link, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { REGEX_URL_MAP } from "../../constants/commons";
import { TSetupWebsiteForm } from "../../types/website.type";
import { focusValidation } from "../../utils/common";

const extractIframeSrc = (text: string) => {
  const match = text.match(
    /<iframe[^>]+src=["'](https:\/\/www\.google\.com\/maps\/embed\?pb=[^"']+)["']/
  );
  return match ? match[1] : text;
};
type TValue = TSetupWebsiteForm["branches"][number];

interface IUploadBranch {
  onValueChange: (value: TValue) => void;
  dfValue?: TValue;
}

const BranchForm = (props: IUploadBranch) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dfValue, onValueChange } = props;
  const onFinish = (values: TValue) => {
    onValueChange(values);
  };

  useEffect(() => {
    form.setFieldsValue(dfValue);
  }, [dfValue]);

  return (
    <Form
      form={form}
      name={`${Math.random()}-branch-form`}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={(errorInfo) => {
        focusValidation(
          errorInfo,
          `${!!dfValue ? "edit" : "create"}-branch-form`
        );
      }}
      colon={false}
    >
      <Form.Item
        name="name"
        label={t("formSetup.contact.nameBranch")}
        rules={[
          {
            required: true,
            message: (
              <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                {t("formSetup.contact.requiredNameBranch")}
              </span>
            ),
          },
          {
            max: 255,
            message: t("formSetup.contact.minLocaleBranch"),
          },
        ]}
      >
        <Input
          autoFocus
          placeholder={t("formSetup.contact.nameBranchPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="address"
        label={t("formSetup.contact.addressBranch")}
        rules={[
          {
            required: true,
            message: (
              <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                {t("formSetup.contact.requiredAddressBranch")}
              </span>
            ),
          },
          {
            max: 255,
            message: t("formSetup.contact.minLocaleBranch"),
          },
        ]}
      >
        <Input placeholder={t("formSetup.contact.addressBranchPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("formSetup.contact.emailBranch")}
        rules={[
          {
            required: true,
            message: (
              <span className="text-xs mb-1 block">
                {t("formLogin.requiredEmail")}
              </span>
            ),
          },
          {
            type: "email",
            message: (
              <span className="text-xs mb-1 block">
                {t("formLogin.invalidatedEmail")}
              </span>
            ),
          },
        ]}
      >
        <Input placeholder={t("formSetup.contact.emailBranchPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="phone"
        label={t("formSetup.contact.phoneBranch")}
        rules={[
          {
            min: 10,
            message: (
              <span className="text-xs mb-1 block">
                {t("formProfile.minPhone")}
              </span>
            ),
          },
        ]}
      >
        <Input
          type="text"
          maxLength={11}
          onInput={(event: React.FormEvent<HTMLInputElement>) => {
            const input = event.currentTarget;
            input.value = input.value.replace(/[^0-9]/g, "");
          }}
        />
      </Form.Item>
      <Form.Item
        name="google_map"
        tooltip={t("formSetup.contact.mapNote")}
        label={t("formSetup.contact.localBranch")}
        rules={[
          {
            validator: (_, value) => {
              const embedRegex = REGEX_URL_MAP;
              if (!value || embedRegex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t("formSetup.contact.validLocaleBranch"))
              );
            },
          },
          {
            max: 1000,
            message: t("formSetup.contact.minLocaleBranch"),
          },
        ]}
      >
        <Input
          placeholder={t("formSetup.contact.localeBranchPlaceholder")}
          prefix={
            <div className="-ml-1 text-gray-500">
              <Link className="w-4" />
            </div>
          }
          suffix={
            !!form.getFieldValue("google_map") ? (
              <div className="hover:bg-gray-100 px-1 cursor-pointer w-fit rounded-md -mr-2 text-gray-500">
                <X
                  className="w-4"
                  onClick={() => form.setFieldsValue({ google_map: "" })}
                />
              </div>
            ) : (
              <></>
            )
          }
          onPaste={(e) => {
            const text = e.clipboardData.getData("text");
            const cleanUrl = extractIframeSrc(text);
            form.setFieldsValue({ google_map: cleanUrl });
            e.preventDefault();
          }}
        />
      </Form.Item>
      <div className="flex gap-2 items-center justify-end mt-4">
        <Button
          type="primary"
          htmlType="submit"
          className="transition-all success"
        >
          {t(`formSetup.contact.${!!dfValue ? "editBtn" : "saveBtn"}`)}
        </Button>
      </div>
    </Form>
  );
};

export default BranchForm;
