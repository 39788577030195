import { Button, Skeleton, Spin } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  CheckCircle,
  ExternalLink,
  Heart,
  MessageSquare,
  Plus,
  RefreshCcw,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { path } from "../../routes/path";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { useGetAlbumsApi } from "../../services/apis/album.api";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import { TAlbum } from "../../types/album.type";
import { IModal } from "../../types/common.type";
import { TWebAlbumModal } from "../../types/website.type";
import { DEFAULT_ALBUMS } from "../SetupWebsite/Products";
import { subject } from "@casl/ability";

const ListAlbum = (props: IModal<TWebAlbumModal>) => {
  const { t } = useTranslation();
  const { setIsOpen } = props;
  const sortMode = useRootSelector((state) => state.sortSearch.mode);
  const valueSearch = useRootSelector((state) => state.sortSearch.valueSearch);
  const { albums: dfAlbums } = useRootSelector((state) => state.website);
  const ability = useContext(AbilityContext);
  const { ref, inView } = useInView();
  const user = useRootSelector((state) => state.user.userProfile);
  const [checkedAlbums, setCheckedAlbums] = useState<TAlbum[]>(DEFAULT_ALBUMS);
  const isCreateAlbum = ability.can("can", PERMISSION.CREATE_ALBUM);
  const dispatch = useDispatch();
  const {
    data: albumsData,
    isLoading: loadingAlbums,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
  } = useGetAlbumsApi({ sortMode, valueSearch });
  const albumsResponse: TAlbum[] = useMemo(() => {
    return albumsData?.pages.reduce((acc, page) => {
      return [...acc, ...page?.data?.data];
    }, []);
  }, [albumsData]);
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    setCheckedAlbums(dfAlbums);
  }, [dfAlbums]);

  let numberLimit: any =
    user.membership_plan.plan_permissions?.find(
      (per) => per.name === PERMISSION.NUM_ALBUM
    ).number || 0;

  numberLimit = numberLimit > 999 ? "∞" : numberLimit;

  return (
    <div>
      <div className="flex justify-between items-start sm:items-center mb-4 sm:flex-row flex-col">
        <span> {t("formSetup.product.modalAlbum.title")}</span>
        <div className="flex gap-4">
          <div className="flex items-center gap-1">
            {t("formSetup.product.modalAlbum.total")}
            <b>{albumsData?.pages[0]?.data?.meta?.total}</b>
          </div>
          <div className="flex items-center gap-1">
            {t("formSetup.product.modalAlbum.albumPerMonth")}
            <b>
              {user.album_in_month}/{numberLimit}
            </b>
          </div>
        </div>
      </div>
      <div
        id="album-list"
        className="h-[400px] relative flex-1 overflow-y-scroll scroll-ruler mb-4 -mx-2 px-2"
      >
        <div className={`grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2`}>
          {loadingAlbums ? (
            <div className="overflow-hidden">
              <Skeleton active className="py-4" />
            </div>
          ) : (
            <>
              <button
                onClick={() => {
                  setIsOpen({
                    status: "create",
                    config: {
                      allow_face_finder: false,
                      comment_password: false,
                      is_commentable: false,
                      is_downloadable: false,
                      password: false,
                    },
                  });
                }}
                disabled={!isCreateAlbum}
                className={`relative border transition-all border-dashed rounded-xl bg-[#f6f8fa] ${
                  !isCreateAlbum
                    ? "cursor-not-allowed opacity-60 border-primary"
                    : "border-secondary hover:bg-gray-200/60"
                }`}
              >
                <div
                  className={`w-full flex gap-1 items-center justify-center flex-col h-full absolute`}
                >
                  <Plus />
                  <div>{t("dashboard.btnCreate")}</div>
                </div>
                <div className="pb-[100%]"></div>
              </button>
              {albumsResponse &&
                albumsResponse?.map((album) => (
                  <div
                    key={album?.id}
                    className={`relative w-full rounded-xl cursor-pointer flex bg-gray-400`}
                    onClick={() => {
                      setCheckedAlbums((pre) => {
                        if (pre.find((item) => item.id === album.id))
                          return pre.filter((item) => item.id !== album.id);
                        else {
                          const checked = ability.can(
                            "add",
                            subject(PERMISSION.NUM_ALBUM_WEBSITE, {
                              max: checkedAlbums.length,
                            })
                          );
                          return checked ? [...pre, album] : [...pre];
                        }
                      });
                    }}
                  >
                    {!!checkedAlbums.find((item) => item.id === album.id) && (
                      <CheckCircle className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-secondary w-14 h-14 z-20" />
                    )}
                    <img
                      src={album?.thumbnail_url}
                      className={`object-cover absolute top-0 left-0 w-full h-full rounded-xl`}
                      alt=""
                    />
                    <div className="pb-[100%]"></div>
                    <div
                      className={`absolute inset-0 justify-end rounded-xl p-3 ${
                        !!album?.thumbnail_url &&
                        "bg-gradient-to-t from-black/70 to-transparent"
                      } flex text-white flex-col`}
                    >
                      <div className="font-semibold flex flex-col">
                        <span className="block text-sm sm:text-lg line-clamp-2">
                          {album?.name}
                        </span>
                        <div className="text-xs text-gray-50">
                          {t("dashboard.createdDate")}:{" "}
                          {dayjs(album?.created_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      <div className="flex items-center justify-between text-gray-50">
                        <div className="flex text-xs space-x-2">
                          <div className="flex gap-1 items-center">
                            <Heart className="w-3" />
                            {album?.like_count}
                          </div>
                          <div className="flex gap-1 items-center">
                            <MessageSquare className="w-3" />
                            {album?.comment_count}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          <div ref={ref} className="h-4">
            {isFetchingNextPage ? (
              <div className="py-6 w-full flex justify-center fixed bottom-6 left-1/2 -translate-x-1/2">
                <Spin size="large" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-start sm:items-center mb-4 sm:flex-row flex-col">
        <div className="flex items-center gap-1">
          {t("formSetup.product.modalAlbum.control")}
          <Link
            to={path.dashboard}
            className="text-orange-600 flex items-center flex-nowrap gap-1 hover:text-orange-600 hover:opacity-60 transition-all"
          >
            {t("formSetup.product.modalAlbum.gotoAdmin")}
            <ExternalLink className="w-4" />
          </Link>
        </div>
        <div
          onClick={() => {
            refetch();
            document
              .getElementById("album-list")
              .scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="flex items-center gap-1 cursor-pointer text-orange-600 hover:opacity-60 transition-all"
        >
          <RefreshCcw className={`w-4 ${isFetching ? "animate-spin" : ""}`} />
          {t("formSetup.product.modalAlbum.loadAgain")}
        </div>
      </div>
      <div className="flex">
        <Button
          type="primary"
          htmlType="submit"
          className="transition-all ml-auto success"
          onClick={() => {
            dispatch(setSetupWebsite({ albums: checkedAlbums }));
            setIsOpen(null);
          }}
        >
          {t("formSetup.product.modalAlbum.addAlbum")}
        </Button>
      </div>
    </div>
  );
};

export default ListAlbum;
