import { TOption } from "../types/common.type";
import { TSetupWebsiteForm } from "../types/website.type";

export const SOCIAL: TOption<keyof TSetupWebsiteForm["contacts"]>[] = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "tiktok",
    label: "Tiktok",
  },
  {
    value: "x",
    label: "X (witter)",
  },
  {
    value: "instagram",
    label: "Instagram",
  },
];

export const CHANEL_CONTACT: TOption<keyof TSetupWebsiteForm["contacts"]>[] = [
  {
    value: "zalo",
    label: "Zalo",
  },
  {
    value: "messenger",
    label: "Messenger",
  },
  {
    value: "telegram",
    label: "Telegram",
  },
  {
    value: "line",
    label: "Line",
  },
];

const DEFAULT_MENU_LAYOUT: TSetupWebsiteForm["menu_layout"] = "left";

const DEFAULT_THEME: TSetupWebsiteForm["theme"] = "light";

const DEFAULT_ALBUM_LAYOUT: TSetupWebsiteForm["album_layout"] = "grid";
const DEFAULT_VIDEO_LAYOUT: TSetupWebsiteForm["album_layout"] = "grid";

export const DEFAULT_GENERAL_SETTING: Partial<TSetupWebsiteForm> = {
  title: "",
  slogan: "",
  menu_layout: DEFAULT_MENU_LAYOUT,
  theme: DEFAULT_THEME,
};

export const DEFAULT_INTRODUCE: Partial<TSetupWebsiteForm> = {
  intro_background: "photo",
  intro_content: "",
  intro_images: [],
};

export const DEFAULT_PRODUCTS: Partial<TSetupWebsiteForm> = {
  albums: [],
  videos: [],
  album_layout: DEFAULT_ALBUM_LAYOUT,
  video_layout: DEFAULT_VIDEO_LAYOUT,
  enable_videos: true,
};

export const DEFAULT_ESTIMATES: Partial<TSetupWebsiteForm> = {
  pricing_images: [],
  pricing_note: "",
  enable_pricing_images: true,
  enable_pricing_note: true,
};

export const DEFAULT_CONTACT: Partial<TSetupWebsiteForm> = {
  branches: [],
  contacts: {
    facebook: "",
    tiktok: "",
    x: "",
    instagram: "",
    zalo: "",
    messenger: "",
    telegram: "",
    line: "",
    youtube: "",
  },
  enable_branches: true,
  enable_facebook: true,
  enable_instagram: true,
  enable_line: true,
  enable_messgener: true,
  enable_telegram: true,
  enable_tiktok: true,
  enable_x: true,
  enable_zalo: true,
};

export const DEFAULT_SETUP_WEBSITE = {
  ...DEFAULT_GENERAL_SETTING,
  ...DEFAULT_INTRODUCE,
  ...DEFAULT_PRODUCTS,
  ...DEFAULT_ESTIMATES,
  ...DEFAULT_CONTACT,
};
