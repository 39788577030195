import { Button, Form, Input } from "antd";
import Upload from "antd/es/upload/Upload";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Check,
  Clipboard,
  Edit2,
  RefreshCcw,
  Trash2,
  Upload as UploadIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import MessageModal from "../../components/MessageModal";
import {
  fetchCheckDomain,
  useGetMeApi,
  useUpdateSetting,
} from "../../services/apis/user.api";
import { useRootSelector } from "../../stores/reducers/root";
import { AbilityContext, PERMISSION } from "../../services/ability.service";

export default function Setting() {
  const { t } = useTranslation();
  const { refetch } = useGetMeApi();
  const ability = useContext(AbilityContext);
  const [copy, setCopy] = useState<number[]>([]);

  const [uploadedFile, setUpLoadedFile] = useState<File | string>();
  const [fav, setFav] = useState<File | string>();

  const [validUploaded, setValidUploaded] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const [form] = Form.useForm();
  const user = useRootSelector((state) => state.user.userProfile);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingReload, setIsLoadingReload] = useState<boolean>(false);
  const [notification, setNotification] = useState<string | null>(null);

  useEffect(() => {
    form.setFieldsValue({
      sub_domain_name: user?.setting?.sub_domain_name
        ? user?.setting?.sub_domain_name?.split(".")?.shift()
        : "",
      domain: user?.setting?.domain,
    });
    setUpLoadedFile(user?.setting?.main_logo || "");
    setFav(user?.setting?.fav_logo || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const mutation = useUpdateSetting();

  const sub_domain_name = Form.useWatch("sub_domain_name", form);
  const domain = Form.useWatch("domain", form);
  const onFinish = (values: any) => {
    const formData = new FormData();
    if (typeof uploadedFile === "object") {
      formData.append("main_logo", uploadedFile);
    }

    if (typeof fav === "object") {
      formData.append("fav_logo", fav);
    }

    formData.append("sub_domain_name", values?.sub_domain_name);
    formData.append("domain", values?.domain);
    formData.append("_method", "PUT");

    // if (!!inputRef.current) inputRef.current.value = '';

    mutation.mutate(formData, {
      onSuccess: (res) => {
        setError("");
        setNotification(t("formProfile.success"));
        refetch();
      },
      onError: (error) => {
        setError(error.response?.data.message || t("formProfile.error"));
        setNotification("");
      },
    });
  };

  const handleOpenFile = () => {
    if (!!inputRef.current) inputRef.current.click();
  };

  const handleChange = (event: any) => {
    if (event?.target.files) {
      setUpLoadedFile(event?.target.files[0]);
    }
  };

  const handleClick = () => {
    setError("");
    setNotification("");
    if (typeof uploadedFile === "string" || !uploadedFile) {
      return;
    } else {
      if (uploadedFile.size / 1024 / 1024 > 10) {
        const temp = t("formCreateAlbum.checkValidImage.size10");
        setValidUploaded(temp);
        return;
      }
    }
  };

  return (
    <>
      <div className="relative py-8 flex flex-col items-center md:max-w-[686px] m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t("formSetting.title")}
        </div>
        <div className="relative w-full h-[1px] bg-slate-900/30 text-slate-500 my-5 text-sm">
          <span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white px-3 whitespace-nowrap">
            {t("formSetting.subTitle")}
          </span>
        </div>
        <Form
          form={form}
          name="register-form"
          layout="vertical"
          className="w-full flex flex-col mt-4"
          onFinish={onFinish}
        >
          <Form.Item
            name="sub_domain_name"
            label={t("formProfile.subdomainName")}
            validateFirst
            rules={[
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: t("formSetting.subdomainNameError"),
              },
            ]}
          >
            <div className="flex items-end">
              <Input autoFocus value={sub_domain_name} className="py-2 px-3" />
              <span className="ml-2">.shotpik.com</span>
            </div>
          </Form.Item>
          {ability.can("can", PERMISSION.PRIVATE_DOMAIN) && (
            <>
              <Form.Item
                name="domain"
                label={t("formProfile.domain")}
                tooltip={t("formSetting.domainNote")}
                rules={[
                  {
                    pattern: /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
                    message: t("formSetting.domainError"),
                  },
                ]}
              >
                <div className="flex gap-2 flex-col">
                  {user?.setting?.domain_info?.status && user?.setting?.domain_info?.status !== "active" && (
                    <div className="px-1 text-xs text-red-500">
                      {t("formSetting.domainWarning")}
                    </div>
                  )}
                  <div className="flex gap-2 items-center">
                    <Input className="py-2 px-3" value={domain} />
                    <div
                      className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
                        isLoadingReload
                          ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
                          : "hover:bg-gray-200/50"
                      }`}
                      onClick={async () => {
                        setIsLoadingReload(true);
                        await fetchCheckDomain();
                        refetch();
                        setIsLoadingReload(false);
                      }}
                    >
                      <RefreshCcw
                        className={`w-4 ${
                          isLoadingReload ? "animate-spin" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="flex flex-col gap-3">
                {user?.setting?.domain_info?.name_servers && (
                  <div className="text-sm flex items-center gap-1">
                    <div>Name server</div>
                    {user?.setting?.domain_info?.status === "active" && (
                      <Check className="text-secondary pb-1 w-7 h-7" />
                    )}
                  </div>
                )}
                {user?.setting?.domain_info?.name_servers?.map(
                  (element: string, index: number) => {
                    return (
                      <div key={index} className="flex gap-3 items-center">
                        <Input
                          className="flex-1 !cursor-default !text-gray-600 border-0 py-2 px-3 !bg-gray-100"
                          value={element}
                          disabled
                        />
                        <div
                          onClick={() => {
                            navigator.clipboard
                              .writeText(element)
                              .then(() => {});
                            setCopy((prev) => [...prev, index]);
                            setTimeout(
                              () =>
                                setCopy((prev) =>
                                  prev.filter((item) => item !== index)
                                ),
                              1000
                            );
                          }}
                          className="text-secondary flex gap-2 items-center cursor-pointer py-1 px-3 rounded-2xl hover:bg-[#0bcdac26] transition-all"
                        >
                          {copy.includes(index) ? (
                            <Check className="w-4" />
                          ) : (
                            <Clipboard className="w-4" />
                          )}
                          {t(
                            "dashboard.albums.action.download.modalShare.btnCopy"
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}

          <div className="relative flex mt-3 gap-3 mb-6">
            <div className="relative w-24 h-24">
              {typeof uploadedFile === "string" || !uploadedFile ? (
                <div className="relative w-full h-full">
                  <input
                    type="file"
                    ref={inputRef}
                    accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
                    className="!hidden"
                    onChange={handleChange}
                  />
                  {user?.setting?.main_logo ? (
                    <>
                      <div
                        onClick={handleOpenFile}
                        className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-lg bg-gray-200/80 text-slate-700 cursor-pointer"
                      >
                        <Edit2 className="w-4" />
                      </div>
                      <img
                        src={user?.setting?.main_logo}
                        alt="thumbnail_url-default"
                        className="w-full h-full rounded-lg object-cover"
                      />
                    </>
                  ) : (
                    <div
                      onClick={handleOpenFile}
                      className="relative w-full h-full flex justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg cursor-pointer"
                    >
                      <UploadIcon className="w-5" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative w-full h-full">
                  <div
                    onClick={() => {
                      setUpLoadedFile(undefined);
                    }}
                    className="absolute top-1 right-1 flex justify-center items-center cursor-pointer w-7 h-7 rounded-lg bg-gray-200/80 text-slate-700"
                  >
                    <Trash2 className="w-4" />
                  </div>
                  <img
                    src={URL.createObjectURL(uploadedFile)}
                    alt="uploaded"
                    className="w-full h-full rounded-lg object-cover"
                  />
                </div>
              )}
            </div>
            <div className="flex-1 w-full relative flex flex-col justify-between py-4">
              {t("formProfile.logo")}
              <span className="text-xs text-slate-500">
                {t("formSetting.noteLogo")}
              </span>
            </div>
            <span className="absolute -bottom-6 left-0 text-xs mb-1 block text-primary">
              {validUploaded}
            </span>
          </div>

          <div className="flex gap-3 w-full mb-6">
            <Upload
              accept=".jpg, .jpeg, .png, .gif, .webp, .favicon"
              showUploadList={false}
              beforeUpload={(file) => {
                console.log(file);
                setFav(file);
                return false;
              }}
              className="!w-24 !h-24 relative cursor-pointer"
            >
              {typeof fav === "string" || !fav ? (
                <div className="relative !w-24 !h-24">
                  {user?.setting?.fav_logo ? (
                    <>
                      <div className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-lg bg-gray-200/80 text-slate-700 cursor-pointer">
                        <Edit2 className="w-4" />
                      </div>
                      <img
                        src={user?.setting?.fav_logo}
                        alt="thumbnail_url-default"
                        className="w-full h-full rounded-lg object-cover"
                      />
                    </>
                  ) : (
                    <div className="relative w-full h-full flex justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg cursor-pointer">
                      <UploadIcon className="w-5" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative !w-24 !h-24">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setFav(undefined);
                    }}
                    className="absolute top-1 right-1 flex justify-center items-center cursor-pointer w-7 h-7 rounded-lg bg-gray-200/80 text-slate-700"
                  >
                    <Trash2 className="w-4" />
                  </div>
                  <img
                    src={
                      typeof fav === "string" ? fav : URL.createObjectURL(fav)
                    }
                    alt="uploaded"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              )}
            </Upload>
            <div className="flex-1 w-full relative flex flex-col justify-between py-4">
              Favicon
              <span className="text-xs text-slate-500">
                {t("formSetting.noteFavicon")}
              </span>
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={mutation.isLoading}
              onMouseDown={() => handleClick()}
            >
              {t("formProfile.btn")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <MessageModal
        isVisible={!!error || !!notification}
        onClose={() => {
          setError(null);
          setNotification(null);
        }}
        title={!!error ? t("Error") : notification}
        subTitle={error}
        type={!!error ? "error" : "success"}
        btnText={t("btnClose")}
      />
    </>
  );
}
