import { Form, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import UploadPhoto from "../UploadPhoto";

const DEFAULT_ESTIMATES = [""];

const Estimate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dfPricingImages = useRootSelector(
    (state) => state.website.pricing_images
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <div className="md:w-32 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-2 md:gap-1">
          <Form.Item
            name="enable_pricing_images"
            layout="horizontal"
            className="mb-0"
          >
            <Switch defaultChecked className="w-fit" />
          </Form.Item>
          <span className="md:whitespace-pre-line">
            {t("formSetup.estimate.createEstimate")}
          </span>
          <div className="text-xs text-orange-600">
            {t("formSetup.estimate.note")}
          </div>
        </div>
        <Form.Item
          name="pricing_images"
          layout="horizontal"
          className="mb-0 flex-1 w-full bg-gray-100 rounded-lg scroll-ruler overflow-x-auto"
        >
          <div className="w-max flex gap-2 p-2">
            {[...dfPricingImages, ...DEFAULT_ESTIMATES].map(
              (estimate, index) => {
                return (
                  <div
                    key={index}
                    className={`w-32 sm:w-44 lg:w-48 h-24 sm:h-32`}
                  >
                    <UploadPhoto
                      dfUrl={estimate}
                      onValueChange={(data) => {
                        if (!data)
                          dispatch(
                            setSetupWebsite({
                              pricing_images: dfPricingImages.filter(
                                (_item, idx) => index !== idx
                              ),
                            })
                          );
                        else {
                          const updatedEstimates = [...dfPricingImages];
                          updatedEstimates[index] = data;
                          dispatch(
                            setSetupWebsite({
                              pricing_images: updatedEstimates,
                            })
                          );
                        }
                      }}
                      content={t("formSetup.estimate.addPage")}
                    />
                  </div>
                );
              }
            )}
          </div>
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <div className="md:w-32 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-2 md:gap-1">
          <Form.Item
            name="enable_pricing_note"
            layout="horizontal"
            className="mb-0"
          >
            <Switch defaultChecked />
          </Form.Item>
          <span className="md:whitespace-pre-line">
            {t("formSetup.estimate.content")}
          </span>
        </div>
        <Form.Item
          name="pricing_note"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <TextArea
            autoSize
            placeholder={t("formSetup.estimate.contentPlaceholder")}
            maxLength={200}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default Estimate;
