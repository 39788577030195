import { TMembershipPlanPrice } from "../types/membership.type";
import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const createURLSearchParams = (object: any): string => {
  const ignoreValues = ["", "undefined", "null", null, undefined];
  let params = new URLSearchParams(object);
  let keysForDel: string[] = [];
  params.forEach((value, key) => {
    if (ignoreValues.includes(value)) {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach((key) => {
    params.delete(key);
  });
  return params.toString();
};

export const clusterPackage = (list: TMembershipPlanPrice[]) => {
  let a: Record<number, TMembershipPlanPrice[]> = {};
  for (const value of list) {
    if (!a[value?.membership_plan_id]) {
      a[value?.membership_plan_id] = [value];
    } else {
      a[value?.membership_plan_id] = [...a[value?.membership_plan_id], value];
    }
  }
  return a;
};

export const focusValidation = (errorInfo: any, formName: string) => {
  const firstErrorField = errorInfo.errorFields[0];
  if (firstErrorField) {
    const inputNode = document.getElementById(
      `${formName}_${firstErrorField.name[0]}`
    );
    if (inputNode) {
      (inputNode as HTMLElement).focus();
    }
  }
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertToEmbedUrl(youtubeUrl: string) {
  const regex =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([^&\s]+)/;
  const match = youtubeUrl.match(regex);

  if (match && match[5]) {
    return `https://www.youtube.com/embed/${match[5]}`;
  } else {
    return null;
  }
}

export const getYouTubeThumbnail = (
  url: string,
  quality: "mq" | "hq" | "maxres" = "maxres"
) => {
  const match = url.match(
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/
  );
  if (!match) return "";

  return `https://img.youtube.com/vi/${match[1]}/${quality}default.jpg`;
};
