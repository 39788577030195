import { Form, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { MdNightlightRound, MdOutlineLightMode } from "react-icons/md";
import layoutCenterImg from "../../assets/images/layout-center.png";
import layoutLeftImg from "../../assets/images/layout-left.png";
import layoutRightImg from "../../assets/images/layout-right.png";
import { TOption } from "../../types/common.type";
import { TSetupWebsiteForm } from "../../types/website.type";

type TLayoutOption = TOption<TSetupWebsiteForm["menu_layout"]> & {
  icon: string;
};

type TThemeOption = TOption<TSetupWebsiteForm["theme"]> & {
  icon: IconType;
};

const Generalsettings = () => {
  const { t } = useTranslation();
  const theme = Form.useWatch("theme");
  const layoutList: TLayoutOption[] = [
    {
      value: "left",
      label: t("formSetup.generalSetting.layoutLeft"),
      icon: layoutLeftImg,
    },
    {
      value: "center",
      label: t("formSetup.generalSetting.layoutCenter"),
      icon: layoutCenterImg,
    },
    {
      value: "right",
      label: t("formSetup.generalSetting.layoutRight"),
      icon: layoutRightImg,
    },
  ];
  const themeList: TThemeOption[] = [
    {
      value: "light",
      label: t("formSetup.generalSetting.themeLight"),
      icon: MdOutlineLightMode,
    },
    {
      value: "dark",
      label: t("formSetup.generalSetting.themeDark"),
      icon: MdNightlightRound,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <span className="block sm:w-32 text-left w-full">
          {t("formSetup.generalSetting.name")}
        </span>
        <Form.Item
          name="title"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                  {t("formSetup.generalSetting.validName")}
                </span>
              ),
            },
          ]}
        >
          <Input placeholder={t("formSetup.generalSetting.namePlaceholder")} />
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <span className="block sm:w-32 text-left w-full">
          {t("formSetup.generalSetting.slogan")}
        </span>
        <Form.Item
          name="slogan"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                  {t("formSetup.generalSetting.validSlogan")}
                </span>
              ),
            },
          ]}
        >
          <Input
            placeholder={t("formSetup.generalSetting.sloganPlaceholder")}
          />
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <span className="block sm:w-32 text-left w-full">
          {t("formSetup.generalSetting.layout")}
        </span>
        <Form.Item
          name="menu_layout"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <Radio.Group
            name="menu_layout"
            className="flex items-center gap-0 sm:gap-10 lg:gap-20 justify-between md:justify-start"
          >
            {layoutList.map((item) => {
              return (
                <div
                  className="flex flex-col justify-center items-center"
                  key={item.value}
                >
                  <Radio className="cursor-default text-gray-600" disabled>
                    <img
                      src={item.icon as string}
                      alt="layout-img"
                      className="object-contain w-20 sm:w-32"
                    />
                  </Radio>
                  <Radio value={item.value} className="text-nowrap">
                    {item.label}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
        <span className="block sm:w-32 text-left w-full">
          {t("formSetup.generalSetting.theme")}
        </span>
        <Form.Item
          name="theme"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <Radio.Group
            name="theme"
            className="flex sm:grid sm:grid-cols-3 justify-between gap-1"
          >
            {themeList.map((item) => {
              return (
                <div className="flex items-center" key={item.value}>
                  <Radio
                    className="cursor-default text-gray-600 z-10 bg-white"
                    disabled
                  >
                    <item.icon className="text-4xl sm:text-6xl -rotate-[30]" />
                  </Radio>
                  <Radio value={item.value} className="-ml-8">
                    <span
                      className={`${
                        theme === item.value ? "font-semibold" : ""
                      }`}
                    >
                      {item.label}
                    </span>
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );
};

export default Generalsettings;
