export const VALID_TOKEN = "INVALID_TOKEN";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const DISPLAY_VIEW = ["0", "1"];

export const SORT_MODE = ["created_at desc", "created_at asc"];

export const SORT_ALBUM = [
  "file_name asc",
  "file_name desc",
  "uploaded_at desc",
  "uploaded_at asc",
];

export const FILTER_PHOTO = {
  all: "",
  recommend: "is_recommended",
  like: "is_liked",
  comment: "is_commented",
};

export const DEFAULT_LANGUAGE = "en";

export const REGEX_URL_MAP = /^https:\/\/www\.google\.com\/maps\/embed\?pb=.+$/;

export const REGEX_URL_YOUTUBE =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\/.+)?([^&\s]+)(.*)?$/;

export const REGEX_URL_IMAGE = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;

export enum PATH_ALBUM {
  DASHBOARD = "dashboard",
  SHOW = "show",
  ANO = "albums",
}
