export const authEndpoints = {
  LOGIN: "/api/v1/auth/login",
  REGISTER: "/api/v1/auth/signup",
  FORGOTPASS: "/api/v1/auth/forgot-password",
  RESETPASS: "/api/v1/auth/reset-password",
};

export const albumEndpoints = {
  CHECK_ACCESS_LINK: "/api/v1/check-access-link",
  CREATE_ALBUM: "/api/v1/albums",
  GET_ALBUMS: "/api/v1/albums",
  DELETE_ALBUM: "/api/v1/albums",
  EDIT_ALBUM: (id: string | number) => `/api/v1/albums/${id}`,
  GET_ALBUM_SYNC: (id: string) => `/api/v1/albums/${id}/sync`,
  CHECK_PASS_ALBUM: "/api/v1/client/albums",
  GET_ALBUM_BASIC: (id: string) => `/api/v1/client/albums/${id}/basic`,
  GET_ALBUM_PUBLIC: (id: string) => `/api/v1/client/albums/${id}/public`,
};

export const userEndpoint = {
  PROFILE: "api/v1/profile",
  SETTING: "api/v1/update-user-setting",
  CHECK_DOMAIN: "api/v1/check-domain",
};

export const fileEndpoint = {
  RECOMMEND: (id: string | number) => `/api/v1/files/${id}/recommend`,
  LIKED: (id: string | number) => `/api/v1/files/${id}/like`,
  COMMENTED: (id: string | number) => `/api/v1/files/${id}/comment`,
  TAG: (id: string | number) => `/api/v1/files/${id}/tag`,
};

export const paymentEndpoint = {
  CREATE_SUBCRIPTION: "/api/v1/subscriptions",
  PAYMENT_PAYPAL_CREATE: "/api/v1/payment/paypal/create",
  PAYMENT_PAYPAL_CHECK: "/api/v1/payment/paypal/verify",
  PAYMENT_NGAN_LUONG_CREATE: "/api/v1/payment/nganluong/create",
  PAYMENT_NGAN_LUONG_CHECK: "/api/v1/payment/nganluong/verify",
  MEMBERSHIP_PLANS: "/api/v1/membership-plans",
  CHECK_DISCOUNT: "/api/v1/discounts",
  UPGRADE_PACKAGE: "/api/v1/subscriptions/calculate-upgrade",
};

export const setupWebEndpoint = {
  CREATE: "/api/v1/website",
  ACTIVE: "/api/v1/website/toggle",
};

export const commonEndpoint = {
  UPLOAD_PHOTO: "/api/v1/upload/image",
};

export const staticPage = {
  DETAIL: (path: string) => `api/v1/static-page/${path}`,
};
