import { useMutation } from 'react-query';
import http from '../http.service';
import { fileEndpoint } from '../endpoints';
import { AxiosError, AxiosResponse } from 'axios';

export const useRecommendApi = () => {
  const mutation = useMutation({
    mutationFn: async (id: string | number) => {
      return await http.post<{ success: boolean }>(fileEndpoint.RECOMMEND(id));
    },
  });
  return mutation;
};

export const useLikedApi = () => {
  const mutation = useMutation<
  AxiosResponse<{ success: boolean }, any>,
  AxiosError<any>,
  any,
  unknown
>({
    mutationFn: async (id: string | number) => {
      return await http.post<{ success: boolean , any}>(fileEndpoint.LIKED(id));
    },
  });
  return mutation;
};

export const useCommentApi = () => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      dataQuery,
    }: {
      id: string | number;
      dataQuery: any;
    }) => {
      return await http.post<{ success: boolean }>(
        fileEndpoint.COMMENTED(id),
        dataQuery
      );
    },
  });
  return mutation;
};

export const useTagApi = () => {
  const mutation = useMutation<
    AxiosResponse<{ data : { id: number; name: string }[] }>,
    AxiosError,
    { id: string | number; tagId: number }
  >({
    mutationFn: async ({ id, tagId }) => {
      return await http.post<{ data : { id: number; name: string }[] }>(fileEndpoint.TAG(id), {
        tag_id: tagId,
      });
    },
  });
  return mutation;
};
