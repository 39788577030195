import { Form, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { REGEX_URL_IMAGE, REGEX_URL_YOUTUBE } from "../../constants/commons";
import { useRootSelector } from "../../stores/reducers/root";
import { ISetupWebsiteFormProps } from "../../types/website.type";
import UploadPhoto from "../UploadPhoto";
import UploadVideo from "../UploadVideo";

type TIntroBg = {
  photo: string;
  video: string;
};

const DEFAULT_INTRBG = {
  photo: "photo",
  video: "video",
};

const DEFAULT_INTRO_IMAGES = [...Array.from({ length: 3 }, () => null)];

const Introduce = ({ form }: ISetupWebsiteFormProps) => {
  const { t } = useTranslation();
  const { intro_images: dfIntroImages, intro_background: dfIntroBg } =
    useRootSelector((state) => state.website);
  const [introBg, setIntroBg] = useState<Partial<TIntroBg>>(DEFAULT_INTRBG);
  const [introImages, setIntroImages] =
    useState<string[]>(DEFAULT_INTRO_IMAGES);

  const handleValueChange = (index: number, path: string) => {
    setIntroImages((pre) => {
      const updatedImages = [...pre];
      updatedImages[index] = path;
      return updatedImages;
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      intro_images: Object.values(introImages).filter((item) => !!item),
    });
  }, [introImages]);

  useEffect(() => {
    if (!!dfIntroImages) {
      setIntroImages((pre) =>
        dfIntroImages.length === 0
          ? DEFAULT_INTRO_IMAGES
          : [...Array.from({ length: 3 }, (_, index) => dfIntroImages[index])]
      );
    }
  }, [dfIntroImages]);

  useEffect(() => {
    setIntroBg(() => {
      if (!!dfIntroBg) {
        if (REGEX_URL_IMAGE.test(dfIntroBg)) {
          return { ...DEFAULT_INTRBG, photo: dfIntroBg };
        } else if (REGEX_URL_YOUTUBE.test(dfIntroBg))
          return { ...DEFAULT_INTRBG, video: dfIntroBg };
      }
      return { ...DEFAULT_INTRBG };
    });
  }, [dfIntroBg]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
          <span className="block md:w-32 text-left w-full">
            {t("formSetup.introduce.background")}
          </span>
          <Form.Item
            name="intro_background"
            layout="horizontal"
            className="mb-0 flex-1 w-full"
          >
            <Radio.Group
              name="intro_background"
              className="flex items-center gap-0 sm:gap-10 lg:gap-20 sm:justify-start justify-between"
            >
              <div className="flex flex-col justify-center items-center gap-2">
                <Radio disabled className="m-0">
                  <div className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32 -mx-2">
                    <UploadPhoto
                      dfUrl={introBg.photo === "photo" ? null : introBg.photo}
                      onValueChange={(data) => {
                        !!data && setIntroBg({ photo: data });
                      }}
                      content={t("formSetup.introduce.addPhoto")}
                    />
                  </div>
                </Radio>
                <Radio value={introBg.photo}>
                  {t("formSetup.introduce.photo")}
                </Radio>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <Radio disabled className="m-0">
                  <div className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32 -mx-2">
                    <UploadVideo
                      onValueChange={(data) => {
                        !!data && setIntroBg({ video: data });
                      }}
                      dfValue={introBg.video === "video" ? null : introBg.video}
                    />
                  </div>
                </Radio>
                <Radio value={introBg.video}>
                  {t("formSetup.introduce.video")}
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
          <span className="block md:w-32 text-left w-full">
            {t("formSetup.introduce.content")}
          </span>
          <Form.Item
            name="intro_content"
            layout="horizontal"
            className="mb-0 flex-1 w-full"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                    {t("formSetup.introduce.requireContent")}
                  </span>
                ),
              },
            ]}
          >
            <TextArea
              autoSize
              placeholder={t("formSetup.introduce.contentPlaceholder")}
              maxLength={500}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-8">
          <span className="block md:w-32 text-left w-full">
            {t("formSetup.introduce.photos")}
          </span>
          <Form.Item
            name="intro_images"
            layout="horizontal"
            className="mb-0 flex-1 w-full"
            rules={[
              {
                validator: () => {
                  if (!!introImages && introImages.length === 0) {
                    return Promise.reject(
                      new Error(t("formSetup.introduce.requirePhoto"))
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div className="flex items-center justify-between lg:justify-start lg:gap-20">
              {introImages.map((item, index) => (
                <div
                  className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32"
                  key={index + item}
                >
                  <UploadPhoto
                    dfUrl={item}
                    onValueChange={(data) => {
                      handleValueChange(index, data);
                    }}
                    content={t("formSetup.introduce.addPhoto")}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default Introduce;
