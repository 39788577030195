export const getLikeAnoButton = (isLiked: 0 | 1, isCheckPath?: boolean) => {
  if (isCheckPath && !isLiked) return;
  return `<div class='w-8 h-8 flex gap-1 justify-center items-center ${
    !isCheckPath ? "is-liked" : ""
  }'>
    ${
      isLiked
        ? `<svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        class='text-primary text-2xl mr-1 ${
          isCheckPath ? "cursor-default" : "cursor-pointer"
        }'
        height="22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
      </svg>`
        : `<svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        class="text-white text-2xl mr-1 ${
          isCheckPath ? "cursor-default" : "cursor-pointer"
        }"
        height="22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path>
      </svg>`
    }
    </div>`;
};

export const getRecommendedUserButton = (
  isRecommended: 0 | 1,
  isCheckPath?: boolean,
  text?: string
) => {
  if (!isCheckPath && !isRecommended) return "";
  return `<div class='${!!text ? "flex gap-1 items-center" : ""} ${
    isRecommended ? "text-secondary" : "text-white"
  } ${!isCheckPath ? "cursor-default" : "cursor-pointer"}'>
    ${
      isRecommended
        ? `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" class="text-secondary text-xl opacity-90"  width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>${
            text ? text : ""
          }`
        : `
    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" class="text-white text-xl opacity-90" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>${
      text ? text : ""
    }`
    }
    </div>`;
};

export const getCommentedAnoButton = (defaulValue?: string) => {
  return `<span class="flex !cursor-default border-0 py-2 pl-3 !bg-[#414141] !text-gray-100 hover:!bg-[#414141] rounded-lg  overflow-y-auto">
  <textarea rows='1' disabled autofocus class="max-h-32 scroll-ruler flex-1 w-full h-auto comment !bg-[#414141] !text-gray-100 hover:!bg-[#414141]" type="text">${defaulValue}</textarea>
  <span class="cursor-pointer p-2 pl-1 h-fit -mt-2 edit-comment"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></span>
  </span>`;
};

export const getTagModal = (tags: { id: number; name: string }[], existedTags: {id: number, name: string}[], callbackEvent: (id: number) => void) => {
  let html = `<div
    class="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 text-xs bg-white rounded shadow-lg z-10 group-hover:block"
    id="dropdownTag"
  >
    <div class="flex flex-col w-max min-w-32 max-w-64 divide-y divide-gray-300">
      ${tags
        .map(
          (tag) =>
            `<div class="tag-item max-w-32 whitespace-nowrap overflow-hidden text-ellipsis px-4 py-2 ${existedTags.some(t => t.id === tag.id) ? "bg-gray-200 text-gray-500 pointer-events-none cursor-not-allowed" : "bg-secondary/80 hover:bg-secondary cursor-pointer"}" data-id="${tag.id}">${tag.name}</div>`
        )
        .join("")}
    </div>
  </div>`;
  const tagModal = new DOMParser()
    .parseFromString(html, "text/html")
    .getElementById("dropdownTag");

  tagModal.querySelectorAll(".tag-item").forEach((item) => {
    item.addEventListener("click", () => {
      callbackEvent(Number(item.getAttribute("data-id")));
      tagModal.remove();
    });
  });
  return tagModal;
};

export const getTagDeleteButton = (isCheckPath: boolean, i: number, tag: { id: number; name: string }, callbackEvent: (index: number, id: number) => void) => {
  let html = `
  <span class="tag-item max-w-32 whitespace-nowrap overflow-hidden text-ellipsis ml-1 text-xs px-2 py-1 rounded-lg bg-secondary/80 hover:bg-secondary" data-index="${i}" data-id="${tag.id}">
    ${tag.name}
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tag-delete ml-1 w-4 inline-block cursor-pointer ${isCheckPath ? "hidden" : ""}" data-index="${i}" data-id="${tag.id}" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
  </span>`;
  const tagItem = new DOMParser()
    .parseFromString(html, "text/html")
    .querySelector(".tag-item");
    if(!isCheckPath) {
      addEventTag(tagItem, callbackEvent);
    }
  return tagItem;
};

export const addEventTag = (tagItem: Element, callbackEvent: (index: number, id: number) => void) => {
  tagItem.querySelectorAll(".tag-delete").forEach((item) => {
    item.addEventListener("click", function(e) {
      if(document.getElementById('dropdownTag')) {
        document.getElementById('dropdownTag').remove();
      }
      callbackEvent(Number(this.getAttribute("data-index")), Number(this.getAttribute("data-id")));
    });
  });
};
