import { Modal } from "antd";
import { memo, useState } from "react";
import { Trash2, Upload as UploadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { FaRegCirclePlay } from "react-icons/fa6";
import { TSetupWebsiteForm } from "../../types/website.type";
import { getYouTubeThumbnail } from "../../utils/common";
import VideoForm from "./VideoForm";

interface IUploadVideo {
  onValueChange: (value: TSetupWebsiteForm["videos"][number]) => void;
  dfValue?: TSetupWebsiteForm["videos"][number];
  disabled?: boolean;
}

const UploadVideo = memo((props: IUploadVideo) => {
  const { dfValue, onValueChange, disabled = false } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="relative w-full h-full">
        <div
          className={`relative w-full h-full ${
            disabled ? "cursor-default opacity-50" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!disabled) setIsModalOpen(true);
          }}
        >
          {!!dfValue ? (
            <div className="relative w-full h-full bg-white rounded-lg">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onValueChange(null);
                }}
                className="absolute z-30 top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
              >
                <Trash2 className="w-4" />
              </div>
              {/* <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(true);
                }}
                className="absolute top-1 right-10 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
              >
                <Edit2 className="w-4" />
              </div> */}
              {/* <iframe
                className="w-full h-full rounded-lg"
                src={convertToEmbedUrl(dfValue)}
                title="video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe> */}
              <img
                src={getYouTubeThumbnail(dfValue)}
                alt="uploaded"
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="flex text-white flex-col absolute inset-0 h-full bg-gradient-to-t from-black/40 to-transparent z-20 rounded-lg">
                <FaRegCirclePlay className="text-xl text-white m-auto" />
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex flex-col gap-2 justify-center bg-white items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg transition-all text-xs sm:text-sm">
              <UploadIcon className="w-5" />
              <span>{t("formSetup.introduce.addVideo")}</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("formSetup.introduce.video")}
          </div>
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        <VideoForm
          onValueChange={(data) => {
            if (!!data) {
              onValueChange(data);
            }
            setIsModalOpen(false);
          }}
          dfValue={dfValue}
        />
      </Modal>
    </>
  );
});

export default UploadVideo;
