import React, { useState } from "react";
import { Input, Tag } from "antd";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const TagInput = ({
  value = [],
  max = 5,
  onChange,
}: {
  value?: { id: string | null; name: string }[];
  max?: number;
  onChange?: (value: { id: string | null; name: string }[]) => void;
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleClose = (removedTag: string) => {
    const newTags = value.filter((tag) => tag.name !== removedTag);
    onChange?.(newTags);
  };

  const handleInputConfirm = () => {
    if (inputValue.trim() && !value.some((tag) => tag.name === inputValue.trim())) {
      const newTag = { id: null, name: inputValue.trim() };
      onChange?.([...value, newTag]);
    }
    setInputValue("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputConfirm();
    }
  };

  const handleAddClick = () => {
    handleInputConfirm();
  };

  return (
    <div
      className="flex items-center flex-wrap gap-1 py-2 px-3 border border-[#d9d9d9] hover:border-secondary focus-within:border-secondary rounded-md transition-all cursor-text"
      onClick={(e) => e.currentTarget.querySelector("input")?.focus()}
    >
      {value.map((tag, index) => (
        <Tag
          key={index}
          closeIcon={<CloseCircleOutlined className="relative -top-0.5" />}
          closable
          onClose={() => handleClose(tag.name)}
          className="m-0 text-base"
        >
          {tag.name}
        </Tag>
      ))}
      <div className="flex items-center gap-1 flex-1">
        <Input
          disabled={value.length >= max}
          type="text"
          size="small"
          maxLength={50}
          className={`flex-1 pl-0 min-w-[100px] border-none focus:border-none focus:outline-none focus:ring-0 shadow-none !ring-0 !outline-none ${value.length >= max ? "hidden" : ""}`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={value.length >= max ? "" : t("Enter Tag")}
        />
        <PlusOutlined
          onClick={handleAddClick}
          className={`text-lg text-secondary cursor-pointer ${value.length >= max ? "hidden" : ""}`}
        />
      </div>
    </div>
  );
};

export default TagInput;
