import { createSlice } from "@reduxjs/toolkit";
import { TWebsite } from "../../types/website.type";

const initialState: TWebsite = {
  album_layout: "grid",
  albums: [],
  branches: [],
  contacts: {
    facebook: "",
    instagram: "",
    line: "",
    messenger: "",
    telegram: "",
    tiktok: "",
    x: "",
    youtube: "",
    zalo: "",
  },
  id: 0,
  intro_background: "photo",
  intro_content: "",
  intro_images: [],
  logo: "",
  menu_layout: "left",
  on: true,
  pricing_images: [],
  pricing_note: "",
  slogan: "",
  theme: "light",
  title: "",
  user_id: 0,
  video_layout: "grid",
  videos: [],
};

const setupWebsiteSlice = createSlice({
  name: "setup-website",
  initialState: initialState,
  reducers: {
    setSetupWebsite: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSetupWebsite } = setupWebsiteSlice.actions;

export default setupWebsiteSlice.reducer;
