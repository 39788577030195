import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { Link, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { REGEX_URL_YOUTUBE } from "../../constants/commons";
import { TSetupWebsiteForm } from "../../types/website.type";
import { focusValidation } from "../../utils/common";

type TValue = TSetupWebsiteForm["videos"][number];

type TForm = { video: string };

interface IUploadVideo {
  onValueChange: (value: TValue) => void;
  dfValue?: TValue;
}

const VideoForm = (props: IUploadVideo) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dfValue, onValueChange } = props;
  const onFinish = (values: TForm) => {
    onValueChange(values.video);
  };

  useEffect(() => {
    form.setFieldsValue({ video: dfValue });
  }, [dfValue]);

  return (
    <Form
      form={form}
      name={`${Math.random()}-video-form`}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={(errorInfo) => {
        focusValidation(
          errorInfo,
          `${!!dfValue ? "edit" : "create"}-video-form`
        );
      }}
      colon={false}
    >
      <Form.Item
        name="video"
        label={t("formSetup.introduce.linkVideo")}
        rules={[
          {
            validator: (_, value) => {
              const embedRegex = REGEX_URL_YOUTUBE;
              if (!value) {
                return Promise.reject(
                  new Error(t("formSetup.introduce.requireVideo"))
                );
              }
              if (!embedRegex.test(value)) {
                return Promise.reject(
                  new Error(t("formSetup.introduce.validVideo"))
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          autoFocus
          prefix={
            <div className="-ml-1 text-gray-500">
              <Link className="w-4" />
            </div>
          }
          suffix={
            !!form.getFieldValue("video") ? (
              <div className="hover:bg-gray-100 px-1 cursor-pointer w-fit rounded-md -mr-2 text-gray-500">
                <X
                  className="w-4"
                  onClick={() => form.setFieldsValue({ video: "" })}
                />
              </div>
            ) : (
              <></>
            )
          }
        />
      </Form.Item>
      <div className="flex gap-2 items-center justify-end mt-4">
        <Button
          type="primary"
          htmlType="submit"
          className="transition-all success"
        >
          {t(`formSetup.contact.${!!dfValue ? "editBtn" : "saveBtn"}`)}
        </Button>
      </div>
    </Form>
  );
};

export default VideoForm;
